import Vue from 'vue'
import App from './App.vue'
import UnvergessenButton from './components/global/UnvergessenButton.vue'
import UnvergessenTooltip from './components/global/UnvergessenTooltip.vue'
import UnvergessenPolaroid from './components/global/UnvergessenPolaroid.vue'
import UnvergessenPremiumAlert from './components/global/UnvergessenPremiumAlert.vue'
import SuccessDialog from '@/components/global/SuccessDialog.vue'
import WaveSearch from '@/components/global/WaveSearch.vue'
import WavePage from '@/components/global/WavePage.vue'
import WaveHome from '@/components/global/WaveHome.vue'
import Wave from '@/components/global/Wave.vue'
import HeaderCard from './components/blog/HeaderCard.vue'
import router from './router/router.js'
import store from './store/store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueCookie from 'vue-cookie'
import VueMeta from 'vue-meta'
import VueAnalytics from 'vue-analytics'
import VueFacebookLogin from 'vue-facebook-login-component'
import JwtDecode from 'jwt-decode'

const jwt = require('jsonwebtoken')

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_API_URL

const token = jwt.sign({}, process.env.VUE_APP_JWT_KEY, { expiresIn: '168h' })

axios.defaults.headers.common.secret = 'Bearer ' + token

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 401 && store.getters.isLoggedIn) {
        store.dispatch('logout')
      }
      if (error.response.status === 404) {
        store.dispatch('sendTo404', router.currentRoute.path)
        router.push({ name: '404' })
        throw error
      }
      if (error.response.status === 503) {
        // If 503 Bad Gateway appears => request failed => Push to offline page
        if (router.currentRoute.name !== 'OfflinePage') {
          store.commit('setReturnTo', router.currentRoute)
        }
        router.push({ name: 'OfflinePage' }).catch(() => {})
      }
    } else {
      // If no response appears => request failed => Push to offline page
      if (router.currentRoute.name !== 'OfflinePage') {
        store.commit('setReturnTo', router.currentRoute)
      }
      router.push({ name: 'OfflinePage' }).catch(() => {})
    }
    throw error
  }
)

if (localStorage.unvergessenToken) {
  const token = localStorage.unvergessenToken
  const decodedToken = JwtDecode(token)
  if (decodedToken.exp * 1000 < Date.now()) {
    localStorage.removeItem('unvergessenToken')
  } else if (decodedToken.userId === null) {
    axios.defaults.headers.common.Authorization = 'Bearer ' + token
  } else {
    axios.defaults.headers.common.Authorization = 'Bearer ' + token
    store.dispatch('setLoggedIn')
  }
}

if (localStorage.sid) {
  axios.defaults.headers.common.sid = localStorage.sid
}

setTimeout(() => {
  var source = router.history.current.query.ptnid ? 'partner_' + router.history.current.query.ptnid
    : router.history.current.query.mmid ? 'memorial_post_' + router.history.current.query.mmid
      : router.history.current.query.mail ? 'mail_' + router.history.current.query.mail
        : router.history.current.query.fid ? 'facebook_ad_' + router.history.current.query.fid
          : router.history.current.query.gid ? 'google_ad_' + router.history.current.query.gid
            : router.history.current.query.vid ? 'v_' + router.history.current.query.vid
              : 'direct'
  store.dispatch('initAnalytics', source)
}, 1000)

Vue.component('unvergessen-button', UnvergessenButton)
Vue.component('unvergessen-tooltip', UnvergessenTooltip)
Vue.component('unvergessen-polaroid', UnvergessenPolaroid)
Vue.component('unvergessen-premium-alert', UnvergessenPremiumAlert)
Vue.component('unvergessen-header-card', HeaderCard)
Vue.component('unvergessen-success-dialog', SuccessDialog)
Vue.component('v-facebook-login', VueFacebookLogin)
Vue.component('unvergessen-wave-search', WaveSearch)
Vue.component('unvergessen-wave-page', WavePage)
Vue.component('unvergessen-wave-home', WaveHome)
Vue.component('unvergessen-wave', Wave)

Vue.use(VueCookie)
Vue.use(VueMeta)

if (document.cookie.indexOf('unvergessenCookies=all') > -1) {
  Vue.use(VueAnalytics, {
    id: 'UA-173171010-2',
    router,
    debug: {
      sendHitTask: process.env.NODE_ENV === 'production'
    }
  })
  window.fbq('init', '598726487491837')
  window.fbq('track', 'PageView')
} else {
  Vue.use(VueAnalytics, {
    id: 'UA-173171010-2',
    router,
    // disabled: true,
    disabled: process.env.VUE_APP_MODE === 'sandbox',
    debug: {
      sendHitTask: process.env.NODE_ENV === 'production'
    }
  })
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
